import type { SearchState } from '@faststore/sdk'
import { parseSearchState, SearchProvider } from '@faststore/sdk'
import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import type { AxiosRequestConfig } from 'axios'
import axios from 'axios'
import { applySearchState } from 'src/sdk/search/state'
import Seo from 'src/views/LP/Seo'
import Above from 'src/views/LP/Above'
import Below from 'src/views/LP/Below'
import 'src/styles/pages/product-listing-page.scss'
import 'src/styles/pages/lp.scss'
import type {
  CmsBlock,
  LandingPageQueryQuery,
  LandingPageQueryQueryVariables,
  CmsInstitutionalPage,
} from '@generated/graphql'
import type { Facet, SearchSort } from '@faststore/sdk/dist/types'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import { usePageViewEvent } from 'src/sdk/analytics/hooks/usePageViewEvent'
import { useSession } from 'src/sdk/session'
import { OptionsMap } from 'src/components/search/Sort/SorterInternalComponent'
import { FilterContextProvider } from 'src/contexts/FilterContext/filter-context'
import { useCampaignContext } from 'src/contexts/campaign-context'
import type { DataSpecification } from 'src/utils/lpFacetsHelper'
import { handleKeyTransform } from 'src/utils/lpFacetsHelper'

import productPerPage from '../utils/productPerPage'
import { Helmet } from 'react-helmet-async'

type Props = PageProps<
  LandingPageQueryQuery,
  LandingPageQueryQueryVariables,
  CmsInstitutionalPage & { node: Node }
> & {
  slug: string
  sort?: string
  pageContext: { cmsPageData: { node: Node } }
}

interface PageViewProps {
  setDidMount: React.Dispatch<React.SetStateAction<boolean>>
}

const getSortInUrl = (searchUrl: string) => {
  if (searchUrl?.length > 0) {
    const splitSearch = searchUrl?.split(/[&=]/)

    return splitSearch?.find(
      (item, index) =>
        item === 'sort' &&
        OptionsMap?.[splitSearch?.[index + 1] as keyof typeof OptionsMap]
    )
  }

  return false
}

const useSearchParams = (props: Props, facets?: Facet[]): SearchState => {
  const {
    location: { href, pathname, search },
  } = props

  const selectedFacets = facets

  const sort: string = props.pageContext?.cmsPageData?.node?.config
    ?.slugAndFilterConfig?.sort
    ? props.pageContext?.cmsPageData?.node?.config?.slugAndFilterConfig?.sort
    : 'score_desc'

  return useMemo(() => {
    const maybeState = href ? parseSearchState(new URL(href)) : null
    const searchSort = !getSortInUrl(search) ? sort : maybeState?.sort

    return {
      page: maybeState?.page ?? 0,
      base: maybeState?.base ?? pathname,
      selectedFacets:
        maybeState && maybeState.selectedFacets.length > 0
          ? maybeState.selectedFacets
          : selectedFacets ?? [],
      term: maybeState?.term ?? null,
      sort: (searchSort as SearchSort) ?? 'score_desc',
    }
  }, [href, pathname, selectedFacets, search, sort])
}

function convertFacets(facet: string | undefined | null, index: number) {
  let keyFacet = facet?.replace(' ', '-').toLocaleLowerCase() ?? ''

  if (facet === 'categoria') {
    keyFacet = `category-${index + 1}`
  }

  return keyFacet
}

function getKey(key: string | null | undefined, index: number) {
  return convertFacets(
    key
      ?.normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase(),
    index
  )
}

function PageViewlp({ setDidMount }: PageViewProps) {
  useEffect(() => {
    setDidMount(true)
  }, [])
}

const getCmsData = async (
  setPreview: React.Dispatch<React.SetStateAction<PreviewData>>,
  SearchParams: URLSearchParams
) => {
  const contentType = SearchParams.get('contentType')
  const documentId = SearchParams.get('documentId')
  const versionId = SearchParams.get('versionId')

  if (contentType && documentId && versionId) {
    await axios
      .post('/api/getCmsContent', {
        contentType,
        documentId,
        versionId,
      })
      .then((response) => {
        setPreview(response?.data)
      })
      .catch((error) => {
        console.error('Error -> ', error)
      })
  }
}

function Page(props: Props) {
  const [didMount, setDidMount] = useState(false)
  const [sendEventPage, setSendEventPage] = useState(false)
  const [preview, setPreview] = useState<PreviewData>(null)
  const { person } = useSession()
  const { sendPageViewEvent } = usePageViewEvent('Landing Page', person)
  const { setCampaignPage } = useCampaignContext()
  const [requestData, setRequestData] = useState<
    AxiosRequestConfig | undefined
  >(undefined)

  setCampaignPage(false)

  useEffect(() => {
    const SearchParams = new URLSearchParams(window?.location?.search)

    if (SearchParams) {
      getCmsData(setPreview, SearchParams)
    }
  }, [])

  const timerRef = useRef<NodeJS.Timeout | undefined>()

  useEffect(() => {
    timerRef.current && clearTimeout(timerRef.current)

    timerRef.current = setTimeout(() => {
      if (!sendEventPage) {
        sendPageViewEvent()
        setSendEventPage(true)
      }
    }, 500)
  }, [person])

  useEffect(() => {
    const type =
      props?.path?.indexOf('institucional') > -1 ? 'Landing Page' : 'Category'

    if (didMount) {
      window.insider_object = window.insider_object || {}
      window.insider_object.page = {
        type,
        originalType: type,
      }
    }
  }, [didMount])

  PageViewlp({
    setDidMount,
  })

  const {
    data: { site },
  } = props

  const cmsPageData = preview ?? props.pageContext.cmsPageData.node

  const visibleFilters =
    cmsPageData?.config?.slugAndFilterConfig?.visibleFilters

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectedFacetsFilter: Array<{ key: string; value: string }> = []
  const filterCMS =
    cmsPageData?.config?.slugAndFilterConfig?.filterGroup?.allItems

  useEffect(() => {
    const categoryId = '1'

    const getSepecification = async () => {
      try {
        const { data: dataSpecification } = await axios.post(
          '/api/productSpecification/getSpecificationsFields',
          { categoryId }
        )

        setRequestData(dataSpecification)
      } catch (error) {
        throw new Error(`Um erro ocorreu: ${error.message}`)
      }
    }

    getSepecification()
  }, [])

  const setFilters = () => {
    if (filterCMS?.length) {
      filterCMS.forEach((item, index) => {
        const facetKey = getKey(item?.key, index)

        const value = getFacetValue(item)

        const pushToSelectedFacetsFilter = (newKey: string) => {
          selectedFacetsFilter.push({
            key: newKey,
            value,
          })
        }

        const key = handleKeyTransform(
          facetKey,
          requestData as DataSpecification
        )

        if (requestData) {
          pushToSelectedFacetsFilter(key)
        }
      })
    }
  }

  const mimoRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const hasChildren = !!mimoRef?.current?.childNodes && mimoRef.current.childNodes.length > 0
    
    if (
      'globalHasLoadedLiveShopping' in window &&
      'updateMimoPlayer' in window &&
      'addMimoScript' in window &&
      !hasChildren
      ){
        if ('MimoPlayer' in window) {
          (window.updateMimoPlayer as (d: Document, hlls: boolean) => {})(document, window.globalHasLoadedLiveShopping as boolean);
        }

        (window.addMimoScript as Function)(document, "script", "mimo-jssdk", window.globalHasLoadedLiveShopping as boolean);
    }
  }, [mimoRef.current])

  setFilters()

  const searchParams = useSearchParams(props, selectedFacetsFilter)

  const { width } = useWindowDimensions()

  const ITEM_PER_PAGE_VERIFIED = productPerPage(width)

  return (
    didMount && (
      <SearchProvider
        onChange={applySearchState}
        itemsPerPage={ITEM_PER_PAGE_VERIFIED}
        {...searchParams}
      >
        <Helmet>
          <script data-strategy="post-hydrate" type="text/javascript" async={true}>
            { mimoScript }
          </script>
        </Helmet>
        <FilterContextProvider>
          {/* SEO */}
          <Seo
            data={props.data}
            location={props.location}
            cmsData={cmsPageData}
          />
          {window && window.location?.pathname?.includes('especial/live') ? (
            <div className="container-mimo" ref={mimoRef} />
          ) : null}
          <Above cmsData={cmsPageData} />
          <Below
            cmsData={cmsPageData}
            selectedFacets={selectedFacetsFilter}
            searchParams={searchParams}
            site={site}
            visibleFilters={visibleFilters}
            sendInsiderEvent
          />
        </FilterContextProvider>
      </SearchProvider>
    )
  )
}

function getFacetValue(
  item: {
    key?: string | null
    value?: string | null
  } | null
): string {
  if (!item?.value) {
    return ''
  }

  if (item.key === 'grupo-etário') {
    return item.value
  }

  return item.value
    .replaceAll(' ', '-')
    .replaceAll('+', '-')
    .replaceAll('&', '-')
    .toLocaleLowerCase()
}

/**
 * This query will run during SSG
 * */
export const querySSG = graphql`
  query LandingPageQuery {
    site {
      siteMetadata {
        titleTemplate
        title
        description
        siteUrl
      }
    }
    cmsHome {
      sections {
        name
        data
      }
    }
    cmsGlobalComponents {
      sections {
        name
        data
      }
    }
  }
`
type PreviewData = {
  name: string
  sections: Array<{
    data: CmsBlock['data']
    name: string
  }>
  seo: {
    siteMetadataWithSlug: {
      slug: string | null
      description: string | null
      title: string | null
      titleTemplate: string | null
    } | null
  } | null
  config: {
    slugAndFilterConfig: {
      slug: string
      sort: string
      visibleFilters:
        | { allItems: Array<{ filterKey: string | null } | null> | null }
        | null
        | undefined
      filterGroup: {
        allItems:
          | Array<{
              key: string | null
              value: string | null
            } | null>
          | null
          | undefined
      }
    }
  } | null
} | null

type Node = {
  name: string
  sections: Array<{ data: any; name: string }>
  seo: {
    siteMetadataWithSlug: {
      slug: string | null
      description: string | null
      title: string | null
      titleTemplate: string | null
    } | null
  } | null
  config: {
    slugAndFilterConfig: {
      slug: string | null
      background: string | null
      sort: string | null
      headerType: string | null
      filterGroup: {
        allItems: Array<{
          key: string | null
          value: string | null
        } | null> | null
      } | null
      visibleFilters: {
        allItems: Array<{ filterKey: string | null } | null> | null
      } | null
    } | null
  } | null
}

export default Page

const mimoScript = `
  var intervalTries = 0;
  var globalHasLoadedLiveShopping = false;

  const addMimoScript = (d, s, id, hlls) => {
      var js,
          fjs = d.getElementsByTagName(s)[0];

      if (d.getElementById(id)) {
          return false;
      }
      js = d.createElement(s);
      js.id = id;
      js.onload = function () {
          updateMimoPlayer(d, hlls)
      };
      js.src = "https://sdk.mimo.com.br/index.js";
      d.getElementsByClassName("container-mimo").length > 0 && fjs.parentNode.insertBefore(js, fjs);
  }

  const updateMimoPlayer = (d, hlls) => {
      if (d.getElementsByClassName("container-mimo").length > 0) {
          var liveId = "live-id-not-set";
          var customerId = "3966933d-cb7e-4f66-ae92-a1ac3d33aac4";
          var liveUrl = "https://vivara.com.br/especial/live";
          getOrderFormId()
              .then(function (cartId) {
                  return makePlayerSdk(cartId, liveId, customerId, liveUrl);
              })
              .catch(function (err) {
                  console.log(err);
              });
          function makePlayerSdk(cartId, liveId, customerId, liveUrl) {
              var mimoPlayer = new window.MimoPlayer({
                  liveId: liveId,
                  cartId: cartId,
                  customerId: customerId,
                  liveUrl: liveUrl,
                  middleware: function (store) {
                      return function (next) {
                          return function (action) {
                              const payload = action.payload;
                              if (action.type === "checkout/intent_addToCart") {
                                  addItem({
                                      seller: "1",
                                      quantity: payload.quantity,
                                      id: payload.variant.external_identifier,
                                  })
                                      .then(() => {
                                          store.dispatch({
                                              type: "core/intent_ShowToaster",
                                              payload: "Produto adicionado ao carrinho",
                                          });
                                      })
                                      .catch(() => {
                                          store.dispatch({
                                              type: "core/intent_ShowToaster",
                                              payload: "Erro ao adicionar produto ao carrinho",
                                          });
                                      });
                              }
                              return next(action);
                          };
                      };
                  },
              });
              var mimoContainer = document.createElement("div");
              mimoContainer.style =
                  "height: 100%; width: 100%; position:relative; z-index: 4;";
              mimoContainer.appendChild(mimoPlayer);
              let hasAddedElement = d.body
                  .getElementsByClassName("container-mimo")[0]
                  .appendChild(mimoContainer);
              if (hasAddedElement) {hlls = true}
          }
          function getOrderFormId() {
              return new Promise(function (resolve, reject) {
                  var xhr = new XMLHttpRequest();
                  xhr.open("POST", "/api/getOrderform");
                  xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
                  xhr.setRequestHeader(
                      "Content-Type",
                      "application/json;charset=UTF-8"
                  );
                  xhr.onload = function () {
                      if (xhr.status >= 200 && xhr.status < 300) {
                          resolve(JSON.parse(xhr.response)["orderFormId"]);
                      } else {
                          reject(null);
                      }
                  };
                  xhr.onerror = function () {
                      reject(null);
                  };
                  xhr.send(JSON.stringify({ orderformID: "" }));
              });
          }
          function getProduct(productId, salesChannel) {
              return new Promise((res, rej) => {
                  var xhr = new XMLHttpRequest();
                  xhr.open("POST", "/api/getProductData");
                  xhr.setRequestHeader(
                      "Content-Type",
                      "application/json;charset=UTF-8"
                  );
                  xhr.onload = function () {
                      if (xhr.status >= 200 && xhr.status < 300) {
                          if (xhr.response) {
                              res(JSON.parse(xhr.response).product);
                          } else {
                              rej(false);
                          }
                      } else {
                          rej(null);
                      }
                  };
                  xhr.onerror = function () {
                      rej(null);
                  };
                  xhr.send(
                      JSON.stringify({
                          productId,
                          channel: JSON.stringify({
                              salesChannel,
                              regionId: "",
                          }),
                      })
                  );
              });
          }
          function addItem(item = {}) {
              return new Promise(async function (resolve, reject) {
                  try {
                      const product = await getProduct(item.id, item.seller);
                      const newItem = {
                          seller: { identifier: item.seller },
                          quantity: item.quantity,
                          price: product.offers.offers[0].price,
                          listPrice: product.offers.offers[0].listPrice,
                          itemOffered: {
                              sku: product.sku,
                              name: product.name,
                              image: product.image,
                              brand: product.brand,
                              isVariantOf: product.isVariantOf,
                              gtin: product.gtin,
                              additionalProperty: product.additionalProperty,
                          },
                          id: product.id,
                      };
                      const store = faststore_sdk_stores.get("fs::cart");
                      const cart = store.read();
                      store.set({
                          ...cart,
                          items: [...cart.items, newItem],
                      });
                      resolve(true);
                  } catch (error) {
                      reject(error);
                  }
              });
          }
          hlls = true;
      }
  }

  window.updateMimoPlayer = updateMimoPlayer
  window.addMimoScript = addMimoScript
`
